nav {
    margin-bottom: 2%;
}

#v-pills-tabContent.tab-content {
    /*width: 100%;*/
    padding-right: 0.4rem;
}

.statistics-row {
    height: 10rem;
}

.chart {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
}

#nav-tab button:first-child{
    margin-left: 0.4rem;
}

#nav-tabContent {
    padding-left: 0.4rem;
}

.panel img{
    width: 100%;
    max-width: 200px;
}

a {
    text-decoration: none;
}

.date > input,
.date > span {
    cursor: pointer;
}

.table-condensed {
    width: 15rem;
}