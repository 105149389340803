.center-contents:has(div.raceat-content) {
    background-color: #070504;
    background-image:
        radial-gradient(white, rgba(255,255,255,.2) 2px, transparent 40px),
        radial-gradient(white, rgba(255,255,255,.15) 1px, transparent 30px),
        radial-gradient(white, rgba(255,255,255,.1) 2px, transparent 40px),
        radial-gradient(rgba(255,255,255,.4), rgba(255,255,255,.1) 2px, transparent 30px);
    background-size: 550px 550px, 350px 350px, 250px 250px, 150px 150px;
    background-position: 0 0, 40px 60px, 130px 270px, 70px 100px;
}

.sticky-top:has(> div.raceat-header) {
    background-color: transparent;
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
}

.sticky-top:has(> div.raceat-header) .menu-mobile {
    background-color: transparent;
}

.raceat-header {
    position: relative;
    height: 15rem;
}

.pc .raceat-header {
    height: 17rem;
}

.raceat-header::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    
    top: 0;
    left: 0;
}

.pc .raceat-header img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-mask-image: linear-gradient(to bottom, rgba(255,255,255,1) 0, rgba(255,255,255,1) 92%, rgba(255, 255, 255, 0) 100%);
    mask-image: linear-gradient(to bottom, rgba(255,255,255,1) 0, rgba(255,255,255,1) 92%, rgba(255, 255, 255, 0) 100%);
}

.pc .body-sticky-top:has(> div.body-sticky-top-top) .raceat-header img {
    position: absolute;
    top: -61px;
    width: 100%;
    height: calc(100% + 45px);
    object-fit: cover;
    -webkit-mask-image: linear-gradient(to bottom, rgba(255,255,255,1) 0, rgba(255,255,255,1) 92%, rgba(255, 255, 255, 0) 100%);
    mask-image: linear-gradient(to bottom, rgba(255,255,255,1) 0, rgba(255,255,255,1) 92%, rgba(255, 255, 255, 0) 100%);
}

.mobile .raceat-header img {
    position: absolute;
    top: -121px;
    width: 100%;
    height: calc(100% + 80px);
    object-fit: cover;
    -webkit-mask-image: linear-gradient(to bottom, rgba(255,255,255,1) 0, rgba(255,255,255,1) 92%, rgba(255, 255, 255, 0) 100%);
    mask-image: linear-gradient(to bottom, rgba(255,255,255,1) 0, rgba(255,255,255,1) 92%, rgba(255, 255, 255, 0) 100%);
}

.fireplace-spark {
    height: 4px;
    width: 4px;
    border-radius: 1px;
    top: 0;
    left: 0;
    filter: blur(1px);
    background: yellow;
    z-index: 10;
    opacity: 0;
}

.pc .fireplace-spark:nth-of-type(1) {
    animation: spark_1 1s forwards infinite;
}

.pc .fireplace-spark:nth-of-type(2) {
    animation: spark_2 1s 0.75s forwards infinite;
}
  
.pc .fireplace-spark:nth-of-type(3) {
    animation: spark_3 1s 0.25s forwards infinite;
}
  
.pc .fireplace-spark:nth-of-type(4) {
    animation: spark_4 1s 0.5s forwards infinite;
}

@keyframes spark_1 {
    0% {
        opacity: 1;
        left: 31%;
        top: 66%;
    }
    99% {
        opacity: 0;
    }
    100% {
        top: 19%;
        left: 34%;
        opacity: 0;
    }
}
  
  @keyframes spark_2 {
    0% {
        opacity: 1;   
        left: 56%;
        top: 66%;
    }
    99% {
        opacity: 0;
    }
    100% {
        top: 6%;
        left: 47%;
        opacity: 0;
    }
}
  
  @keyframes spark_3 {
    0% {
        opacity: 1;   
        left: 69%;
        top: 66%;
    }
    99% {
        opacity: 0;
    }
    100% {
        top: 9%;
        left: 53%;
        opacity: 0;
    }
}
  
  @keyframes spark_4 {
    0% {
        opacity: 1;  
        left: 38%;
        top: 66%;
    }
    99% {
        opacity: 0;
    }
    100% {
        top: 9%;
        left: 23%;
        opacity: 0;
    }
}

.raceat-header-content .raceat-header-title {
    height: 70%;
}

.raceat-header-content .raceat-header-sub {
    height: 30%;
}

.mobile .raceat-title-sub {
    height: 2.4rem;
    font-size: 2rem;
    color: #ffc70f;
    font-family: "Yuji Mai", serif;
    font-weight: 400;
    font-style: normal;
    transform: rotate(0.003deg);
}

.pc .raceat-title-sub {
    height: 1.0rem;
    font-size: 2rem;
    color: #ffc70f;
    font-family: "Yuji Mai", serif;
    font-weight: 400;
    font-style: normal;
    transform: rotate(0.003deg);
}

.mobile .raceat-title {
    font-size: 3rem;
    height: 5.3rem;
    color: #F8F8FF;
    font-family: "Kaisei Decol", serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    transform: scale(1, 2);
    filter: drop-shadow(2px 2px 2px black) drop-shadow(-2px -2px 2px black);
}

.pc .raceat-title {
        font-size: 5.5rem;
        color: #F8F8FF;
        font-family: "Kaisei Decol", serif;
        font-optical-sizing: auto;
        font-weight: 700;
        font-style: normal;
        transform: scale(0.5, 1);
        filter: drop-shadow(2px 2px 2px black) drop-shadow(-2px -2px 2px black);
}

.raceat-title-badge {
    font-size: 1.4rem;
    font-weight: 400;
}

.raceat-term {
    font-size: 1rem;
}

.raceat-ticket {
    background-image: radial-gradient(at 50% 10%, #4c4875, #1e112f 58%, #0c0415);
    width: 30%;
    height: 50%;
    border: #7527d845 solid 1px;
}

.raceat-ticket-count {
    border: #7527d845 solid 1px;
}

.join-button {
    position: relative;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: min(13vw, 100px);
    height: min(13vw, 100px);
    background: radial-gradient(at center 80%, #D85627, #2F1211 80%);
    box-shadow: inset 0 0 10px rgba(150, 122, 0, 0.6);
    color: #FFF;

    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5), 0px 0px 4px rgba(255, 255, 255, 1);
    text-decoration: none;
    text-align: center;
    margin: 10px 0;
}

.join-button-disabled {
    background: radial-gradient(at center 80%, #d7c9c4, #312e2e 80%);
}

.join-button:active {
    transform: translate(2px, 2px);
}

.join-button .highlight {
    position: absolute;
    border-radius: 100px;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(250, 250, 255, 1) 0%, rgba(250, 250, 255, 0.7) 10%, rgba(250, 250, 255, 0) 100%);
    z-index: 3;
    top: -16%;
    transform: scale(0.8, 0.6);
}

h4.raceat-heading {
    position: relative;
    padding: 0.25em 0;
    margin: 0;
}

h4.raceat-heading::after {
    content: "";
    display: block;
    height: 3px;
    background: -webkit-linear-gradient(to right, #6620ce, transparent);
    background: linear-gradient(to right, #6620ce, transparent);
}

@property --scrollbar {
    syntax: "<color>";
    inherits: true;
    initial-value: rgba(0, 0, 0, 0);
}

.raceat-content > div {
    transition: --scrollbar .3s;
}

.raceat-content > div::-webkit-scrollbar {
    background: transparent;
}

.raceat-content > div::-webkit-scrollbar-thumb {
    background: var(--scrollbar);
    border-radius: 7px;
}

.raceat-ranking {
    height: 20rem;
    background-color: rgba(234, 190, 255, 0.15);
}

.raceat-ranking-inner {
    height: auto;
}

.raceat-ranking-inner:hover {
    --scrollbar: #CE2020;
}

.raceat-ranking table tr {
    height: 5rem;
}

.raceat-ranking table th {
    width: 15%;
}

.raceat-ranking table td.ranking-name {
    width: 45%;
}

.raceat-myrank {
    height: 8rem;
    background-color: rgba(234, 190, 255, 0.15);
}

.raceat-myrank table tr {
    height: 5rem;
}

.raceat-myrank table th {
    width: 15%;
}

.raceat-myrank table td.ranking-name {
    width: 45%;
}

.raceat-reward {
    height: 20rem;
    background-color: rgba(234, 190, 255, 0.15);
}

.raceat-reward-inner:hover {
    --scrollbar: #6e27d8;
}

.raceat-reward-now {
    height: 35rem;
    background-color: rgba(234, 190, 255, 0.15);
}

.raceat-description {
    height: 20rem;
    background-color: rgba(234, 190, 255, 0.15);
}

#raceat-description-1 {
    background-image: url("../image/event/raceat/artifact-bokashi.png");
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
}

#raceat-description-2 {
    background-image: url("../image/event/worklifebalance.jpg");
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
}

#raceat-description-3 {
    background-image: url("../image/event/worklifebalance.jpg");
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
}

#raceat-description-4 {
    background-image: url("../image/event/gift.jpg");
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
}

.raceat-description-title {
    top: 0px;
    left: 1%;
}

.raceat-description-title div {
    text-shadow: 1px 1px 0.5px rgb(111, 111, 111), -1px -1px 0.5px rgb(111, 111, 111),
    -1px 1px 0.5px rgb(111, 111, 111),  1px -1px 0.5px rgb(111, 111, 111),
    1px 0 0.5px rgb(111, 111, 111), -1px  0 0.5px rgb(111, 111, 111),
    0 1px 0.5px rgb(111, 111, 111),  0 -1px 0.5px rgb(111, 111, 111);
}

.raceat-content .ribbon {  
    margin: 0;
    padding: 10px 0 7px;
    z-index: 2;
    width: 40px;
    text-align: center;
    color: white;
    font-size: 13px;
    background: #6e27d8;
    border-radius: 2px 0 0 0;
}

.raceat-content .ribbon:after {
    content: '';
    position: absolute;
    left: 0;
    top: 100%;
    height: 0;
    width: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 10px solid #6e27d8;
}

.raceat-description-content {
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
}

.raceat-changes {
    background-color: rgba(234, 190, 255, 0.15);
}

.accordion-item {
    background-color: rgba(234, 190, 255, 0.15);
}

.font-size-L {
    font-size: clamp(12px, 5vw, 40px);
    white-space: nowrap;
    overflow: hidden;
}

.font-size-M {
    font-size: clamp(8px, 3.34vw, 26.67px);
    white-space: nowrap;
    overflow: hidden;
}

.font-size-S {
    font-size: clamp(4px, 1.67vw, 13.34px);
    white-space: nowrap;
    overflow: hidden;
}

.raceat-bingo-row {
    height: 19rem;
}

.raceat-bingo-container {
    width: 13rem;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.raceat-bingo-sheet {
    color: #000;
    padding: 4px;
    transition-duration: 0.3s;
    transform-origin: left bottom;
    /*
    perspective: 1000px;
    transform: rotateX(10deg) rotateY(15deg);
    transition: transform 0.5s ease;
    */
}

.raceat-bingo-sheet[value="1"] {
    background-color: rgb(203, 143, 255);
    border: 2px solid rgb(223, 192, 251);
}

.raceat-bingo-sheet[value="2"] {
    background-color: rgb(143, 166, 255);
    border: 2px solid rgb(192, 200, 251);
}

.raceat-bingo-sheet[value="3"] {
    background-color: rgb(255, 143, 143);
    border: 2px solid rgb(251 192 192);
}

.raceat-bingo-sheet[value="4"] {
    background-color: rgb(220, 180, 115);
    border: 2px solid rgb(251, 242, 192);
}

.raceat-bingo-sheet[value="5"] {
    background-color: rgb(92, 205, 111);
    border: 2px solid rgb(193, 251, 192);
}

.raceat-bingo-sheet[value="6"] {
    background-color: rgb(171, 171, 171);
    border: 2px solid rgb(237, 237, 237);
}

.raceat-bingo-sheet[value="7"] {
    background-color: rgb(30, 30, 30);
    border: 2px solid rgb(200, 200, 200);
}

.raceat-bingo-number {
    font-size: 0.75em;
    color: white;
}

.raceat-bingo-title {
    font-size: 2.5em;
    font-family: "Limelight", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-shadow: 1px 1px 0.5px rgb(255, 255, 255), -1px -1px 0.5px rgb(255, 255, 255),
    -1px 1px 0.5px rgb(255, 255, 255),  1px -1px 0.5px rgb(255, 255, 255),
    1px 0 0.5px rgb(255, 255, 255), -1px  0 0.5px rgb(255, 255, 255),
    0 1px 0.5px rgb(255, 255, 255),  0 -1px 0.5px rgb(255, 255, 255);
    letter-spacing: 0.15em;
}

.raceat-bingo-cells {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    border: 3px solid black;
}

.raceat-bingo-cell {
    text-align: center;
    background-color: white;
    border: 1px solid #000;
    font-size: 1.2em;
    font-weight: bold;
    position: relative;
}

.raceat-bingo-cell .raceat-bingo-target-element .raceat-bingo-cell-icon::before {
    content: "●";
}

.raceat-bingo-cell .raceat-bingo-target-element[value="1"] .raceat-bingo-cell-icon {
    color: #980808;
}

.raceat-bingo-cell .raceat-bingo-target-element[value="2"] .raceat-bingo-cell-icon {
    color: #084298;
}

.raceat-bingo-cell .raceat-bingo-target-element[value="3"] .raceat-bingo-cell-icon {
    color: #986f08;
}

.raceat-bingo-cell .raceat-bingo-target-element[value="4"] .raceat-bingo-cell-icon {
    color: #0f5132;
}

.raceat-bingo-cell .raceat-bingo-target-element[value="5"] .raceat-bingo-cell-icon {
    color: #849512;
}

.raceat-bingo-cell .raceat-bingo-target-element[value="6"] .raceat-bingo-cell-icon {
    color: #570898;
}

.raceat-bingo-cell .raceat-bingo-target-weapon .raceat-bingo-cell-icon {
    filter: brightness(0.3);
}

.raceat-bingo-cell .raceat-bingo-cell-name {
    font-size: 0.85rem;
}

.raceat-bingo-cell.raceat-bingo-cell-checked::after {
    content: '\2713'; /* チェックマーク (✓) */
    font-size: 3rem;
    color: red;
    position: absolute;
    top: 50%; /* 中央に配置 */
    left: 50%;
    transform: translate(-50%, -50%); /* ちょうど真ん中に配置 */
}

.raceat-bingo-reset {
    background-color: rgba(234, 190, 255, 0.15);
}

.raceat-bingo-reset .badge {
    background-color: rgb(106, 42, 147);
}