:root {
    --news-height: 40vh;
    --news-nav-height: 4.5vh;
    --news-border-height: 2px;
    --news-border-color: #495057;
    --background-color: 52,58,64;
    --background-second-color: 108,117,125;
}

/*親要素 */
#floatingMenuNews {
    width: 65vw;
    position: fixed;
    bottom: 0px;
    right: 1%;
    display: flex;
    flex-direction: column;
    gap: 0px;
    z-index: 1031;
}
.pc #floatingMenuNews {
    width: max(35vw, 350px);
}

/* 子要素 */    
.flObjNews {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    gap: 15px;
    transition: all 0.2s ease;
    position: relative;
}
/* 子要素のコンテンツ */
.flObjNews .flObjContentNews {
    /*padding: 1%;*/
    background: rgb(var(--background-color));
    width: 100%;
    height: var(--news-height);
    /*border-radius: 5% 5% 0% 0%;*/
    box-shadow: 0 0 6px 0 rgb(0 0 0 / 15%), 0 4px 5px 0 rgb(0 0 0 / 22%), 0 0 6px 0 rgb(0 0 0 / 15%), 0;
    position: relative;
    border-top: var(--news-border-height) solid var(--news-border-color);
    border-right: var(--news-border-height) solid var(--news-border-color);
    border-bottom: 0;
    border-left: var(--news-border-height) solid var(--news-border-color);
}
.pc .flObjNews .flObjContentNews {
    font-size: 80%;
}

.flObjNews .flObjContentNews ul{
    margin-left: calc(-1 * var(--news-border-height));
}

.flObjNews .flObjContentNews ul.nav-tabs{
    height: var(--news-nav-height);
    margin-top: calc(-1 * var(--news-nav-height));
}

.flObjNews .flObjContentNews ul.nav-tabs li.nav-item{
    height: 100%;
}

.flObjNews .flObjContentNews ul.nav-tabs li.nav-item button{
    height: 100%;
    border: var(--news-border-height) solid var(--news-border-color);
    line-height: 1;
}

.flObjNews .flObjContentNews ul.nav-tabs li.nav-item button.active{
    height: 100%;
    border-top: var(--news-border-height) solid var(--news-border-color);
    border-right: var(--news-border-height) solid var(--news-border-color);
    border-bottom: var(--news-border-height) solid argb(0, 0, 0, 0);
    border-left: var(--news-border-height) solid var(--news-border-color);
}

.resize {
    height: 6px;
    background-color: rgb(var(--background-color));
    cursor: ns-resize;
}

.flObjNews.bottom .flObjContentNews {
    height: 5vh;
    /*border-top: solid white;*/
    cursor : pointer;
    z-index: 1033;
}

.pc .flObjNews.bottom .flObjContentNews {
    height: calc(var(--news-nav-height) + var(--news-border-height));
}

/* ＋アイコン */
.flObjNews.bottom .flObjContentNews:before {
    content: "\f078";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Font Awesome 6 Pro" !important;
    font-weight: 900;
    color: #FFF;
}

/* クリック後の親要素 */
#floatingMenuNews.notshow {
    gap: 0;
}
/* クリック前の各メニュー */
div#floatingMenuNews.notshow > .flObjNews.top {
    gap: 0;
    margin-bottom: -10px;
}
div#floatingMenuNews.notshow > .flObjNews.middle {
    gap: 0;
    margin-bottom: calc(-1 * (var(--news-height) + var(--news-nav-height) + var(--news-border-height)));
}
/* クリック前のFontawesomeアイコン */
div#floatingMenuNews.notshow .flObjNews.bottom a:before {
    content: "\f077";
}

.flObjNews .nav-tabs {
    border: 0;
}

.flObjNews .nav-tabs .nav-link {
    color: white;
    background-color: rgb(var(--background-second-color));
    border-color: rgb(var(--background-color));
}

.flObjNews .nav-tabs .nav-link.active {
    color: white;
    background-color: rgb(var(--background-color));
    margin: 0;
}

.groupSelect {
    width: 95%;
    margin: 0 auto 2% auto;
}

.newsBoard {
    background-color: rgb(var(--background-second-color));
    width: 95%;
    height: 83%;
    border-radius: 1%;
    margin: 0 auto 0 auto;
    overflow: auto;
    border: 1px solid black;
    color: black;
}

.flObjContentNews .tab-content {
    height: 100%;
}

.flObjContentNews .tab-content .tab-pane {
    height: 100%;
}

.flObjContentNews .tab-content .tab-pane .newsBoardPane{
    height: 100%;
}

.flObjContentNews .input-group {
    width: 95%;
    height: 3vh;
    margin: 3% auto 0 auto;
}

.pc .flObjContentNews .input-group {
    font-size: 90%;
    height: 35px;
}

.flObjContentNews .input-group input[type="text"] {
    border-color: black;
}

.flObjContentNews .input-group button {
    color: white;
    border-color: black;
}

#flObgToggleNews {
    position: relative;
}

#flObjNewsMark {
    position: absolute;
    border-radius: 50%;
    top: -25%;
    right: -1%;
    width: 20px;
    height: 20px;
    padding: 0;
    z-index: 1034;
}

#flObjNewsMark.notshow {
    opacity: 0;
}

#flObjNewsMarkLetter {
    font-size: smaller;
}

.newsLine i {
    margin: 0 1%;
}

.newsLineMessage {
    position: relative;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    margin: 1%;
    padding: 2.5%;
    width: fit-content;
    max-width: 75%;
}

.newsLine.mine .newsLineMessage:before {
    content: "";
    position: absolute;
    right: -11px;
    top: calc(50% - 6px);
    border: 6px solid transparent;
    border-left: 6px solid rgb(var(--background-color));
    z-index: 1032;
}

.newsLine.notMine .newsLineMessage:before {
    content: "";
    position: absolute;
    left: -11px;
    top: calc(50% - 6px);
    border: 6px solid transparent;
    border-right: 6px solid white;
    z-index: 1032;
}

.newsLine.notMine {
    justify-content: flex-start;
    align-items: center;
}

.newsLine.notMine .newsLineMessage {
    background-color: white;
}

.newsLineDate {
    font-size: 75%;
    color:#3d4043;
}

.pc .newsLineDate {
    width: 40px;
}

.newsLine.mine .newsLineMessage{
    background-color: rgb(var(--background-color));
    color: white;
}

.newsLineBody:hover .newsLineDate,
.newsLine.hovered .newsLineDate {
    display: none;
}

.newsLine .newsLineEmote {
    display: none;
}

.newsLineBody:hover .newsLineEmote,
.newsLine.hovered .newsLineEmote {
    display: block;
}

.newsLineEmote {
    display: block;
    cursor: pointer;
}

.pc .newsLineEmote {
    width: 30px;
}

.newsLineEmote i {
    color:#3d4043;
}

.newsLineEmote:hover i {
    color:#55595d;
}

.newsLineEmote:active i {
    position: relative;
    top: 1px;
    left: 1px;
}

.emotes {
    padding-left: 30px;
    padding-right: 30px;
}

.emojiCell {
    border-radius: 30%;
    color: white;
    font-size: 0.8rem;
    cursor: pointer;
}

.emojiCell:active {
    position: relative;
    top: 1px;
    left: 1px;
}

.newsLineEmote:active i {
    position: relative;
    top: 1px;
    left: 1px;
}

#flObgToggleNewsEmoji {
    position: absolute;
    z-index: 1033;
}

#flObgToggleNewsEmoji > div{
    position: absolute;
}

em-emoji-picker {
    width: 50vw;
    height: 50vh;
    font-size: 100px;
}

.pc em-emoji-picker {
    width: 243px;
    height: 350px;
}

.news-icon {
    position: relative;
    width: 5vw;
    height: 5vw;
}

.pc .news-icon {
    width: 32px;
    height: 32px;
}

.news-icon-user {
    border: 1px solid;
    border-radius: 50%;
    background-color: rgb(43,48,53);
}

.news-icon a {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    color: rgb(222,226,230);
}

.news-icon img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;   
}

.news-icon-letter {
    font-size: 1rem;
}

.pc .news-icon-letter {
    font-size: 14px;
}

.news-icon.icon-1 {
    border-color: #495057;
    background-color: rgb(43,48,53);
}

.news-icon.icon-101 {
    border-color: #574A49;
    background-color: rgb(53,44,43);
}

.news-icon.icon-102 {
    border-color: #747675;
    background-color: #5B5D5C;
}

.news-icon.icon-103 {
    border-color: #a07115;
    background-color: #CA9F4B;
}

.news-icon.icon-201 {
    border-color: #D9ADAD;
    background-color: #D90D1E;
}

.news-icon.icon-202 {
    border-color: #E9F2A7;
    background-color: #3B7302;
}

.news-icon.icon-203 {
    border-color: #D4E6F3;
    background-color: #3F73DB;
}

.news-icon.icon-301 {
    border-color: #D9ADAD;
    background-color: #c90000;
}

.news-icon.icon-302 {
    border-color: #D4E6F3;
    background-color: #0d25d9;
}

.news-icon.icon-303 {
    border-color: #E9F2A7;
    background-color: #21a955;
}

.news-icon.icon-401 {
    border-color: #D4E6F3;
    background-color: #6a21a9;
}

.news-icon.icon-402 {
    border-color: #E9F2A7;
    background-color: #ffe019;
    text-shadow: 1px 1px 0.5px rgb(125,125,125), -1px -1px 0.5px rgb(125,125,125),
    -1px 1px 0.5px rgb(125,125,125),  1px -1px 0.5px rgb(125,125,125),
    1px 0 0.5px rgb(125,125,125), -1px  0 0.5px rgb(125,125,125),
    0 1px 0.5px rgb(125,125,125),  0 -1px 0.5px rgb(125,125,125);
}

.news-icon.icon-403 {
    border-color: #E9F2A7;
    background-color: #8cff00;
    text-shadow: 1px 1px 0.5px rgb(125,125,125), -1px -1px 0.5px rgb(125,125,125),
    -1px 1px 0.5px rgb(125,125,125),  1px -1px 0.5px rgb(125,125,125),
    1px 0 0.5px rgb(125,125,125), -1px  0 0.5px rgb(125,125,125),
    0 1px 0.5px rgb(125,125,125),  0 -1px 0.5px rgb(125,125,125);
}