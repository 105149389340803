.pc .houchi-filter {
    font-size: min(max(1vw, 12px), 14px);
}

.houchi-row {
    --border-color: rgb(77, 77, 77);
    --background-color: transparent;
    --gradation-color: rgba(0,0,0,.8);

    height: 9.7rem;
    border: solid 1px var(--border-color);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: var(--background-color);
    background-image: -webkit-linear-gradient(45deg,transparent 65%,rgba(255,255,255,.6) 65%,rgba(255,255,255,.6) 100%);
    background-image: linear-gradient(315deg,transparent 65%,var(--gradation-color) 100%), linear-gradient(45deg,transparent 65%,rgba(255,255,255,.6) 65%,rgba(255,255,255,.6) 100%);
    background-repeat: no-repeat;
}

.houchi-row:active {
    position: relative;
    top: 1px;
    left: 1px;
}

.houchi-row[data-element="fire"] {
    --border-color: #980808;
    --background-color: #330303;
    --gradation-color: rgba(48,9,9,.8);
}

.houchi-row[data-element="water"] {
    --border-color: #084298;
    --background-color: #031633;
    --gradation-color: rgba(9,45,48,.8);
}

.houchi-row[data-element="earth"] {
    --border-color: #986f08;
    --background-color: #332303;
    --gradation-color: rgba(48,34,9,.8);
}

.houchi-row[data-element="wind"] {
    --border-color: #0f5132;
    --background-color: #051b11;
    --gradation-color: rgba(9,48,15,.8);
}

.houchi-row[data-element="light"] {
    --border-color: #849512;
    --background-color: #777818;
    --gradation-color: rgba(78,73,28,.8);
}

.houchi-row[data-element="dark"] {
    --border-color: #570898;
    --background-color: #250333;
    --gradation-color: rgba(48,9,44,.8);
}

.houchi-enemy {
    height: 2.7rem;
}

.houchi-enemy-name {
    color: rgb(220,220,220);
    overflow-wrap: break-word;
    text-shadow: 1px 1px 0.5px rgb(45,45,45), -1px -1px 0.5px rgb(45,45,45),
    -1px 1px 0.5px rgb(45,45,45),  1px -1px 0.5px rgb(45,45,45),
    1px 0 0.5px rgb(45,45,45), -1px  0 0.5px rgb(45,45,45),
    0 1px 0.5px rgb(45,45,45),  0 -1px 0.5px rgb(45,45,45);
}

.pc .houchi-badge {
    font-size: min(max(2vw, 6px), 18px);
}

.houchi-message {
    height: 6rem;
    color: black;
    background-color: rgba(255,255,255,.5);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow-y: hidden;
}

.pc .houchi-message {
    font-size: min(max(2.5vw, 8px), 18px);
}

.houchi-icon .profile-icon {
    width: 4vw;
    min-width: 50px;
    max-width: 70px;
    height: 4vw;
    min-height: 50px;
    max-height: 70px;
}