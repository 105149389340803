.center-contents:has(div.race-content) {
    background-color: #070504;
    background-image:
        radial-gradient(white, rgba(255,255,255,.2) 2px, transparent 40px),
        radial-gradient(white, rgba(255,255,255,.15) 1px, transparent 30px),
        radial-gradient(white, rgba(255,255,255,.1) 2px, transparent 40px),
        radial-gradient(rgba(255,255,255,.4), rgba(255,255,255,.1) 2px, transparent 30px);
    background-size: 550px 550px, 350px 350px, 250px 250px, 150px 150px;
    background-position: 0 0, 40px 60px, 130px 270px, 70px 100px;
}

.sticky-top:has(> div.race-header) {
    background-color: transparent;
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
}

.sticky-top:has(> div.race-header) .menu-mobile {
    background-color: transparent;
}

.race-header {
    position: relative;
    height: 15rem;
}

.pc .race-header {
    height: 17rem;
}

.race-header::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    
    top: 0;
    left: 0;
}

.pc .race-header img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-mask-image: linear-gradient(to bottom, rgba(255,255,255,1) 0, rgba(255,255,255,1) 92%, rgba(255, 255, 255, 0) 100%);
    mask-image: linear-gradient(to bottom, rgba(255,255,255,1) 0, rgba(255,255,255,1) 92%, rgba(255, 255, 255, 0) 100%);
}

.pc .body-sticky-top:has(> div.body-sticky-top-top) .race-header img {
    position: absolute;
    top: -61px;
    width: 100%;
    height: calc(100% + 45px);
    object-fit: cover;
    -webkit-mask-image: linear-gradient(to bottom, rgba(255,255,255,1) 0, rgba(255,255,255,1) 92%, rgba(255, 255, 255, 0) 100%);
    mask-image: linear-gradient(to bottom, rgba(255,255,255,1) 0, rgba(255,255,255,1) 92%, rgba(255, 255, 255, 0) 100%);
}

.mobile .race-header img {
    position: absolute;
    top: -121px;
    width: 100%;
    height: calc(100% + 80px);
    object-fit: cover;
    -webkit-mask-image: linear-gradient(to bottom, rgba(255,255,255,1) 0, rgba(255,255,255,1) 92%, rgba(255, 255, 255, 0) 100%);
    mask-image: linear-gradient(to bottom, rgba(255,255,255,1) 0, rgba(255,255,255,1) 92%, rgba(255, 255, 255, 0) 100%);
}

.fireplace-spark {
    height: 4px;
    width: 4px;
    border-radius: 1px;
    top: 0;
    left: 0;
    filter: blur(1px);
    background: yellow;
    z-index: 10;
    opacity: 0;
}

.pc .fireplace-spark:nth-of-type(1) {
    animation: spark_1 1s forwards infinite;
}

.pc .fireplace-spark:nth-of-type(2) {
    animation: spark_2 1s 0.75s forwards infinite;
}
  
.pc .fireplace-spark:nth-of-type(3) {
    animation: spark_3 1s 0.25s forwards infinite;
}
  
.pc .fireplace-spark:nth-of-type(4) {
    animation: spark_4 1s 0.5s forwards infinite;
}

@keyframes spark_1 {
    0% {
        opacity: 1;
        left: 31%;
        top: 66%;
    }
    99% {
        opacity: 0;
    }
    100% {
        top: 19%;
        left: 34%;
        opacity: 0;
    }
}
  
  @keyframes spark_2 {
    0% {
        opacity: 1;   
        left: 56%;
        top: 66%;
    }
    99% {
        opacity: 0;
    }
    100% {
        top: 6%;
        left: 47%;
        opacity: 0;
    }
}
  
  @keyframes spark_3 {
    0% {
        opacity: 1;   
        left: 69%;
        top: 66%;
    }
    99% {
        opacity: 0;
    }
    100% {
        top: 9%;
        left: 53%;
        opacity: 0;
    }
}
  
  @keyframes spark_4 {
    0% {
        opacity: 1;  
        left: 38%;
        top: 66%;
    }
    99% {
        opacity: 0;
    }
    100% {
        top: 9%;
        left: 23%;
        opacity: 0;
    }
}

.race-header-content {

}

.race-header-content .race-header-title {
    height: 70%;
}

.race-header-content .race-header-sub {
    height: 30%;
}

.race-title-sub {
    height: 1.5rem;
    font-size: 2rem;
    color: #CE2020;
    font-family: "Yuji Mai", serif;
    font-weight: 400;
    font-style: normal;
    transform: rotate(0.003deg);
}

.race-title {
    font-size: 4rem;
    color: #F8F8FF;
    font-family: "Noto Sans JP", sans-serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
    transform: rotate(0.003deg);
    text-shadow: 2px 2px 0.5px rgb(0, 0, 0), -2px -2px 0.5px rgb(0, 0, 0),
    -2px 2px 0.5px rgb(0, 0, 0),  2px -2px 0.5px rgb(0, 0, 0),
    2px 0 0.5px rgb(0, 0, 0), -2px  0 0.5px rgb(0, 0, 0),
    0 2px 0.5px rgb(0, 0, 0),  0 -2px 0.5px rgb(0, 0, 0),
    2.5px 2.5px 0.5px rgb(255, 255, 255), -2.5px -2.5px 0.5px rgb(255, 255, 255),
    -2.5px 2.5px 0.5px rgb(255, 255, 255),  2.5px -2.5px 0.5px rgb(255, 255, 255),
    2.5px 0 0.5px rgb(255, 255, 255), -2.5px  0 0.5px rgb(255, 255, 255),
    0 2.5px 0.5px rgb(255, 255, 255),  0 -2.5px 0.5px rgb(255, 255, 255);
}

.race-term {
    font-size: 1rem;
    background-color: #ecd5d3df;
}

.race-ticket {
    background-image: radial-gradient(at 50% 10%, #754C48, #2F1211 58%, #150504);
    width: 30%;
    height: 50%;
    border: #d8562745 solid 1px;
}

.race-ticket-count {
    background-color: #ecd5d3;
    border: #d8562745 solid 1px;
}

.join-button {
    position: relative;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: min(13vw, 100px);
    height: min(13vw, 100px);
    background: radial-gradient(at center 80%, #D85627, #2F1211 80%);
    box-shadow: inset 0 0 10px rgba(150, 122, 0, 0.6);
    color: #FFF;

    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5), 0px 0px 4px rgba(255, 255, 255, 1);
    text-decoration: none;
    text-align: center;
    margin: 10px 0;
}

.join-button-disabled {
    background: radial-gradient(at center 80%, #d7c9c4, #312e2e 80%);
}

.join-button:active {
    transform: translate(2px, 2px);
}

.join-button .highlight {
    position: absolute;
    border-radius: 100px;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(250, 250, 255, 1) 0%, rgba(250, 250, 255, 0.7) 10%, rgba(250, 250, 255, 0) 100%);
    z-index: 3;
    top: -16%;
    transform: scale(0.8, 0.6);
}

h4.race-heading {
    position: relative;
    padding: 0.25em 0;
    margin: 0;
}

h4.race-heading::after {
    content: "";
    display: block;
    height: 3px;
    background: -webkit-linear-gradient(to right, #CE2020, transparent);
    background: linear-gradient(to right, #CE2020, transparent);
}

@property --scrollbar {
    syntax: "<color>";
    inherits: true;
    initial-value: rgba(0, 0, 0, 0);
}

.race-content div {
    transition: --scrollbar .3s;
}

.race-content div::-webkit-scrollbar {
    background: transparent;
}

.race-content div::-webkit-scrollbar-thumb {
    background: var(--scrollbar);
    border-radius: 7px;
}

.race-ranking {
    height: 20rem;
    background-color: rgba(255, 190, 190, 0.15);
}

.race-ranking-inner {
    height: auto;
}

.race-ranking-inner:hover {
    --scrollbar: #CE2020;
}

.race-ranking table tr {
    height: 5rem;
}

.race-ranking table th {
    width: 15%;
}

.race-ranking table td.ranking-name {
    width: 45%;
}

.race-myrank {
    height: 8rem;
    background-color: rgba(255, 190, 190, 0.15);
}

.race-myrank table tr {
    height: 5rem;
}

.race-myrank table th {
    width: 15%;
}

.race-myrank table td.ranking-name {
    width: 45%;
}

.race-reward {
    height: 20rem;
    background-color: rgba(255, 190, 190, 0.15);
}

.race-reward-inner:hover {
    --scrollbar: #CE2020;
}

.race-reward-now {
    height: 35rem;
    background-color: rgba(255, 190, 190, 0.15);
}

.race-description {
    height: 20rem;
    background-color: rgba(255, 190, 190, 0.15);
}

#race-description-1 {
    background-image: url("../image/event/gold-ingot-bokashi.png");
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
}

#race-description-2 {
    background-image: url("../image/event/worklifebalance.jpg");
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
}

#race-description-3 {
    background-image: url("../image/event/gift.jpg");
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
}

.race-description-title {
    top: 0px;
    left: 1%;
}

.race-description-title div {
    text-shadow: 1px 1px 0.5px rgb(111, 111, 111), -1px -1px 0.5px rgb(111, 111, 111),
    -1px 1px 0.5px rgb(111, 111, 111),  1px -1px 0.5px rgb(111, 111, 111),
    1px 0 0.5px rgb(111, 111, 111), -1px  0 0.5px rgb(111, 111, 111),
    0 1px 0.5px rgb(111, 111, 111),  0 -1px 0.5px rgb(111, 111, 111);
}

.race-content .ribbon {  
    margin: 0;
    padding: 10px 0 7px;
    z-index: 2;
    width: 40px;
    text-align: center;
    color: white;
    font-size: 13px;
    background: #D85627;
    border-radius: 2px 0 0 0;
}

.race-content .ribbon:after {
    content: '';
    position: absolute;
    left: 0;
    top: 100%;
    height: 0;
    width: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 10px solid #D85627;
}

.race-description-content {
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
}

.race-changes {
    background-color: rgba(255, 190, 190, 0.15);
}

.accordion-item {
    background-color: rgba(255, 190, 190, 0.15);
}

.font-size-L {
    font-size: clamp(12px, 5vw, 40px);
    white-space: nowrap;
    overflow: hidden;
}

.font-size-M {
    font-size: clamp(8px, 3.34vw, 26.67px);
    white-space: nowrap;
    overflow: hidden;
}

.font-size-S {
    font-size: clamp(4px, 1.67vw, 13.34px);
    white-space: nowrap;
    overflow: hidden;
}