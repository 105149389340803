.profile-background {
    height: 20rem;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    top: -3px;
}

.pc .profile-background {
    background-size: auto 100%;
}

.profile-background.demo {
    height: 10rem;
    background-size: 100% 100%;
}

.profile-background.preview {
    height: 2rem;
    background-size: 100% 100%;
}

.profile-background.background-101 {
    background-image: url("../image/profile/background/sky.png");
}

.profile-background.background-102 {
    background-image: url("../image/profile/background/ring.png");
}

.profile-background.background-103 {
    background-image: url("../image/profile/background/hihi-night.png");
}

.profile-background.background-104 {
    background-image: url("../image/profile/background/earth-girl.png");
}

.profile-background.background-105 {
    background-image: url("../image/profile/background/phenix.png");
}

.profile-background.background-106 {
    background-image: url("../image/profile/background/time-man.webp");
}

.profile-background.background-201 {
    background-color: #3A9BB3;
}

.profile-background.background-201 .background-child1 {
    background-color: #3A9BB3;
    --border-color: #184453;

  /* ランダムな太さの先を重ねる */
  background-image: repeating-conic-gradient(
      transparent 0,
      transparent 13deg,
      var(--border-color) 13deg,
      var(--border-color) 16deg
    ),
    repeating-conic-gradient(
      transparent 0,
      transparent 20deg,
      var(--border-color) 20deg,
      var(--border-color) 23deg
    ),
    repeating-conic-gradient(
      transparent 0,
      transparent 5deg,
      var(--border-color) 5deg,
      var(--border-color) 8deg
    ),
    repeating-conic-gradient(
      transparent 0,
      transparent 2deg,
      var(--border-color) 2deg,
      var(--border-color) 3deg
    );

      /* 真ん中をくり抜く表現 */
    mask-image: radial-gradient(
        transparent 0%,
        transparent 30%,
        white 70%,
        white
    );
    -webkit-mask-image: radial-gradient(
        transparent 0%,
        transparent 30%,
        white 70%,
        white
    );
}

.profile-background.background-202 {
    background-color: #dddddd;
}

.profile-background.background-202 .background-child1 {
    background-color: #dddddd;
    --border-color: #000;

  /* ランダムな太さの先を重ねる */
  background-image: repeating-conic-gradient(
      transparent 0,
      transparent 13deg,
      var(--border-color) 13deg,
      var(--border-color) 16deg
    ),
    repeating-conic-gradient(
      transparent 0,
      transparent 20deg,
      var(--border-color) 20deg,
      var(--border-color) 23deg
    ),
    repeating-conic-gradient(
      transparent 0,
      transparent 5deg,
      var(--border-color) 5deg,
      var(--border-color) 8deg
    ),
    repeating-conic-gradient(
      transparent 0,
      transparent 2deg,
      var(--border-color) 2deg,
      var(--border-color) 3deg
    );

      /* 真ん中をくり抜く表現 */
    mask-image: radial-gradient(
        transparent 0%,
        transparent 30%,
        white 70%,
        white
    );
    -webkit-mask-image: radial-gradient(
        transparent 0%,
        transparent 30%,
        white 70%,
        white
    );
}

.profile-background.background-203 {
    background-color: #FFC107;
}

.profile-background.background-203 .background-child1 {
    background-color: #FFC107;
    --border-color: #7952B3;

  /* ランダムな太さの先を重ねる */
  background-image: repeating-conic-gradient(
      transparent 0,
      transparent 13deg,
      var(--border-color) 13deg,
      var(--border-color) 16deg
    ),
    repeating-conic-gradient(
      transparent 0,
      transparent 20deg,
      var(--border-color) 20deg,
      var(--border-color) 23deg
    ),
    repeating-conic-gradient(
      transparent 0,
      transparent 5deg,
      var(--border-color) 5deg,
      var(--border-color) 8deg
    ),
    repeating-conic-gradient(
      transparent 0,
      transparent 2deg,
      var(--border-color) 2deg,
      var(--border-color) 3deg
    );

      /* 真ん中をくり抜く表現 */
    mask-image: radial-gradient(
        transparent 0%,
        transparent 30%,
        white 70%,
        white
    );
    -webkit-mask-image: radial-gradient(
        transparent 0%,
        transparent 30%,
        white 70%,
        white
    );
}

.profile-background.background-2001 {
    background-image: url("../image/profile/background/event1.png");
}

.profile-background.background-2002 {
    background-image: url("../image/profile/background/event3.png");
}

.profile-background.background-2003 {
    background-image: url("../image/profile/background/event2.png");
}

.profile-background.background-2004 {
    background-image: url("../image/profile/background/event4.png");
}

.profile-bar {
    height: 10.5rem;
}

.profile-nameplate.nameplate-1 {
    border-left:double 5px;
    border-right:double 5px;
    border-color: #495057;
    background-color: rgb(43,48,53);
}

.profile-nameplate.nameplate-101 {
    border-left:double 5px;
    border-right:double 5px;
    border-color: #574A49;
    background-color: rgb(53,44,43);
    background-image:
        -webkit-linear-gradient(45deg,transparent 65%,rgba(255,255,255,.4) 65%,rgba(255,255,255,.4) 80%,transparent 80%),
        -webkit-repeating-linear-gradient(0deg,
            rgba(122, 55, 55, 0.2),
            rgba(122, 55, 55, 0.20) 1px,
            transparent 0,
            transparent 20px),
        -webkit-repeating-linear-gradient(90deg,
            rgba(122, 55, 55, 0.20),
            rgba(122, 55, 55, 0.20) 1px,
            transparent 0,
            transparent 20px);
    background-image:
        linear-gradient(45deg,transparent 65%,rgb(200,200,200) 65%,rgba(200,200,200,1) 80%,transparent 80%),
        repeating-linear-gradient(0deg,
            rgba(122, 55, 55, 0.2),
            rgba(122, 55, 55, 0.20) 1px,
            transparent 0,
            transparent 20px),
        repeating-linear-gradient(90deg,
            rgba(122, 55, 55, 0.20),
            rgba(122, 55, 55, 0.20) 1px,
            transparent 0,
            transparent 20px);
    background-repeat: no-repeat;
    text-shadow: 1px 1px 0.5px rgb(45,45,45), -1px -1px 0.5px rgb(45,45,45),
    -1px 1px 0.5px rgb(45,45,45),  1px -1px 0.5px rgb(45,45,45),
    1px 0 0.5px rgb(45,45,45), -1px  0 0.5px rgb(45,45,45),
    0 1px 0.5px rgb(45,45,45),  0 -1px 0.5px rgb(45,45,45);
}

.profile-nameplate.nameplate-102 {
    border-left:double 5px;
    border-right:double 5px;
    border-color: #747675;
    background-color: #5B5D5C;
    background-image:
        -webkit-linear-gradient(45deg,transparent 65%,rgba(255,255,255,.4) 65%,rgba(255,255,255,.4) 80%,transparent 80%),
        -webkit-repeating-linear-gradient(0deg,
            rgba(130, 130, 130, 0.2),
            rgba(130, 130, 130, 0.20) 1px,
            transparent 0,
            transparent 20px),
        -webkit-repeating-linear-gradient(90deg,
            rgba(130, 130, 130, 0.2),
            rgba(130, 130, 130, 0.20) 1px,
            transparent 0,
            transparent 20px);
    background-image:
        linear-gradient(45deg,transparent 65%,rgb(200,200,200) 65%,rgba(200,200,200,1) 80%,transparent 80%),
        repeating-linear-gradient(0deg,
            rgba(130, 130, 130, 0.2),
            rgba(130, 130, 130, 0.20) 1px,
            transparent 0,
            transparent 20px),
        repeating-linear-gradient(90deg,
            rgba(130, 130, 130, 0.2),
            rgba(130, 130, 130, 0.20) 1px,
            transparent 0,
            transparent 20px);
    background-repeat: no-repeat;
    text-shadow: 1px 1px 0.5px rgb(45,45,45), -1px -1px 0.5px rgb(45,45,45),
    -1px 1px 0.5px rgb(45,45,45),  1px -1px 0.5px rgb(45,45,45),
    1px 0 0.5px rgb(45,45,45), -1px  0 0.5px rgb(45,45,45),
    0 1px 0.5px rgb(45,45,45),  0 -1px 0.5px rgb(45,45,45);
}

.profile-nameplate.nameplate-103 {
    border-left: solid 10px;
    border-right: solid 10px;
    border-color: #a07115;
    background-color: #CA9F4B;
    background-image:
        -webkit-linear-gradient(45deg,transparent 65%,rgba(255,255,255,.4) 65%,rgba(255,255,255,.4) 80%,transparent 80%),
        -webkit-repeating-linear-gradient(0deg,
            rgba(255,255,255, 0.1),
            rgba(255,255,255, 0.1) 1px,
            transparent 0,
            transparent 20px),
        -webkit-repeating-linear-gradient(90deg,
            rgba(255,255,255, 0.1),
            rgba(255,255,255, 0.1) 1px,
            transparent 0,
            transparent 20px);
    background-image:
        linear-gradient(45deg,transparent 65%,rgb(200,200,200) 65%,rgba(200,200,200,1) 80%,transparent 80%),
        repeating-linear-gradient(0deg,
            rgba(255,255,255, 0.1),
            rgba(255,255,255, 0.1) 1px,
            transparent 0,
            transparent 20px),
        repeating-linear-gradient(90deg,
            rgba(255,255,255, 0.1),
            rgba(255,255,255, 0.1) 1px,
            transparent 0,
            transparent 20px);
    background-repeat: no-repeat;
    text-shadow: 1px 1px 0.5px rgb(45,45,45), -1px -1px 0.5px rgb(45,45,45),
    -1px 1px 0.5px rgb(45,45,45),  1px -1px 0.5px rgb(45,45,45),
    1px 0 0.5px rgb(45,45,45), -1px  0 0.5px rgb(45,45,45),
    0 1px 0.5px rgb(45,45,45),  0 -1px 0.5px rgb(45,45,45);
}

.profile-nameplate.nameplate-104 {
    overflow: hidden;
    border-left:double 5px;
    border-right:double 5px;
    border-color: #574A49;
    background-color: rgb(52, 29, 27);
    background-image:
        -webkit-linear-gradient(45deg,transparent 65%,rgb(200,200,200) 65%,rgba(200,200,200,1) 75%,transparent 75%,transparent 77%, rgb(200,200,200) 77%,rgb(200,200,200) 80%,transparent 80%),
        -webkit-repeating-linear-gradient(0deg,
            rgba(122, 55, 55, 0.2),
            rgba(122, 55, 55, 0.20) 1px,
            transparent 0,
            transparent 20px),
        -webkit-repeating-linear-gradient(90deg,
            rgba(122, 55, 55, 0.20),
            rgba(122, 55, 55, 0.20) 1px,
            transparent 0,
            transparent 20px);
    background-image:
        linear-gradient(45deg,transparent 65%,rgb(200,200,200) 65%,rgba(200,200,200,1) 75%,transparent 75%,transparent 77%, rgb(200,200,200) 77%,rgb(200,200,200) 80%,transparent 80%),
        repeating-linear-gradient(0deg,
            rgba(122, 55, 55, 0.2),
            rgba(122, 55, 55, 0.20) 1px,
            transparent 0,
            transparent 20px),
        repeating-linear-gradient(90deg,
            rgba(122, 55, 55, 0.20),
            rgba(122, 55, 55, 0.20) 1px,
            transparent 0,
            transparent 20px);
    background-repeat: no-repeat;
    text-shadow: 1px 1px 0.5px rgb(45,45,45), -1px -1px 0.5px rgb(45,45,45),
    -1px 1px 0.5px rgb(45,45,45),  1px -1px 0.5px rgb(45,45,45),
    1px 0 0.5px rgb(45,45,45), -1px  0 0.5px rgb(45,45,45),
    0 1px 0.5px rgb(45,45,45),  0 -1px 0.5px rgb(45,45,45);
}

.profile-nameplate.nameplate-104::before {
    position: absolute;
    content: '';
    display: inline-block;
    top: -180px;
    right: -100%;
    width: 100%;
    height: 100%;
    background-color: #dbf4ff;
    transition: 300ms;
    animation: plate-shiny 2.5s ease-in-out infinite;
}

.profile-nameplate.nameplate-104 .nameplate-child1 {
    left: -33%;
    background-image: url("../image/logo_64_2.png");
    background-size: auto 80%;
    background-position: center;
    background-repeat: no-repeat;
    mix-blend-mode: luminosity;
    animation: fuwafuwa 4s linear infinite;
}

.profile-nameplate.nameplate-105 {
    overflow: hidden;
    border-left:double 5px;
    border-right:double 5px;
    border-color: #747675;
    background-color: #525e5f;
    background-image:
        -webkit-linear-gradient(45deg,transparent 65%,rgb(255,255,255,.4) 65%,rgba(255,255,255,.4) 75%,transparent 75%,transparent 77%, rgb(255,255,255,.4) 77%,rgb(255,255,255,.4) 80%,transparent 80%),
        -webkit-repeating-linear-gradient(0deg,
            rgba(130, 130, 130, 0.2),
            rgba(130, 130, 130, 0.20) 1px,
            transparent 0,
            transparent 20px),
        -webkit-repeating-linear-gradient(90deg,
            rgba(130, 130, 130, 0.2),
            rgba(130, 130, 130, 0.20) 1px,
            transparent 0,
            transparent 20px);
    background-image:
        linear-gradient(45deg,transparent 65%,rgb(255,255,255,.4) 65%,rgba(255,255,255,.4) 75%,transparent 75%,transparent 77%, rgb(255,255,255,.4) 77%,rgb(255,255,255,.4) 80%,transparent 80%),
        repeating-linear-gradient(0deg,
            rgba(130, 130, 130, 0.2),
            rgba(130, 130, 130, 0.20) 1px,
            transparent 0,
            transparent 20px),
        repeating-linear-gradient(90deg,
            rgba(130, 130, 130, 0.2),
            rgba(130, 130, 130, 0.20) 1px,
            transparent 0,
            transparent 20px);
    background-repeat: no-repeat;
    text-shadow: 1px 1px 0.5px rgb(45,45,45), -1px -1px 0.5px rgb(45,45,45),
    -1px 1px 0.5px rgb(45,45,45),  1px -1px 0.5px rgb(45,45,45),
    1px 0 0.5px rgb(45,45,45), -1px  0 0.5px rgb(45,45,45),
    0 1px 0.5px rgb(45,45,45),  0 -1px 0.5px rgb(45,45,45);
}

.profile-nameplate.nameplate-105::before {
    position: absolute;
    content: '';
    display: inline-block;
    top: -180px;
    right: -100%;
    width: 100%;
    height: 100%;
    background-color: #dbf4ff;
    transition: 300ms;
    animation: plate-shiny 2.5s ease-in-out infinite;
}

.profile-nameplate.nameplate-105 .nameplate-child1 {
    left: -33%;
    background-image: url("../image/logo_64_2.png");
    background-size: auto 80%;
    background-position: center;
    background-repeat: no-repeat;
    mix-blend-mode: luminosity;
    animation: fuwafuwa 4s linear infinite;
}

.profile-nameplate.nameplate-106 {
    overflow: hidden;
    border-left: solid 10px;
    border-right: solid 10px;
    border-color: #a07115;
    background-color: #FFC107;
    background-image:
        -webkit-linear-gradient(45deg,transparent 65%,rgba(255,255,255,.4) 65%,rgba(255,255,255,.4) 75%,rgba(255,255,255,.9) 80%,transparent 80%),
        -webkit-repeating-linear-gradient(0deg,
            rgba(255,255,255, 0.1),
            rgba(255,255,255, 0.1) 1px,
            transparent 0,
            transparent 20px),
        -webkit-repeating-linear-gradient(90deg,
            rgba(255,255,255, 0.1),
            rgba(255,255,255, 0.1) 1px,
            transparent 0,
            transparent 20px);
    background-image:
        linear-gradient(45deg,transparent 65%,rgba(255,255,255,.4) 65%,rgba(255,255,255,.4) 75%,rgba(255,255,255,.9) 80%,transparent 80%),
        linear-gradient(45deg,transparent 65%,rgb(200,200,200) 65%,rgba(200,200,200,1) 80%,transparent 80%),
        repeating-linear-gradient(0deg,
            rgba(255,255,255, 0.1),
            rgba(255,255,255, 0.1) 1px,
            transparent 0,
            transparent 20px),
        repeating-linear-gradient(90deg,
            rgba(255,255,255, 0.1),
            rgba(255,255,255, 0.1) 1px,
            transparent 0,
            transparent 20px);
    background-repeat: no-repeat;
    text-shadow: 1px 1px 0.5px rgb(45,45,45), -1px -1px 0.5px rgb(45,45,45),
    -1px 1px 0.5px rgb(45,45,45),  1px -1px 0.5px rgb(45,45,45),
    1px 0 0.5px rgb(45,45,45), -1px  0 0.5px rgb(45,45,45),
    0 1px 0.5px rgb(45,45,45),  0 -1px 0.5px rgb(45,45,45);
}

.profile-nameplate.nameplate-106::before {
    position: absolute;
    content: '';
    display: inline-block;
    top: -180px;
    right: -100%;
    width: 100%;
    height: 100%;
    background-color: #dbf4ff;
    transition: 300ms;
    animation: plate-shiny 2.5s ease-in-out infinite;
}

.profile-nameplate.nameplate-106 .nameplate-child1 {
    left: -33%;
    background-image: url("../image/logo_64_2.png");
    background-size: auto 80%;
    background-position: center;
    background-repeat: no-repeat;
    mix-blend-mode: luminosity;
    animation: fuwafuwa 4s linear infinite;
}

@keyframes plate-shiny {
    0% { -webkit-transform: scale(0) rotate(45deg); opacity: 0; }
    80% { -webkit-transform: scale(0) rotate(45deg); opacity: 0.5; }
    81% { -webkit-transform: scale(4) rotate(45deg); opacity: 1; }
    100% { -webkit-transform: scale(50) rotate(45deg); opacity: 0; }
}

@keyframes fuwafuwa {
    0%   { transform:translate(0, 0); }
    25%  { transform:translate(0, 2px); }
    50%  { transform:translate(0, 0); }
    75%  { transform:translate(0, -2px); }
    100% { transform:translate(0, 0); }
}

.profile-nameplate.nameplate-201 {
    border-top: solid 1px #D9ADAD;
    border-left: solid 1px #D9ADAD;
    border-right: solid 1px #8C031C;
    border-bottom: solid 1px #8C031C;
    background-color: #D90D1E;
    background-image: -webkit-radial-gradient(transparent 30%, #8C031C 100%);
    background-image:         radial-gradient(transparent 30%, #8C031C 100%);
    background-repeat: no-repeat;
    text-shadow: 1px 1px 0.5px rgb(45,45,45), -1px -1px 0.5px rgb(45,45,45),
    -1px 1px 0.5px rgb(45,45,45),  1px -1px 0.5px rgb(45,45,45),
    1px 0 0.5px rgb(45,45,45), -1px  0 0.5px rgb(45,45,45),
    0 1px 0.5px rgb(45,45,45),  0 -1px 0.5px rgb(45,45,45);
}

.profile-nameplate.nameplate-202 {
    border-top: solid 1px #E9F2A7;
    border-left: solid 1px #E9F2A7;
    border-right: solid 1px #1B4001;
    border-bottom: solid 1px #1B4001;
    background-color: #3B7302;
    background-image: -webkit-radial-gradient(transparent 30%, #1B4001 100%);
    background-image:         radial-gradient(transparent 30%, #1B4001 100%);
    background-repeat: no-repeat;
    text-shadow: 1px 1px 0.5px rgb(45,45,45), -1px -1px 0.5px rgb(45,45,45),
    -1px 1px 0.5px rgb(45,45,45),  1px -1px 0.5px rgb(45,45,45),
    1px 0 0.5px rgb(45,45,45), -1px  0 0.5px rgb(45,45,45),
    0 1px 0.5px rgb(45,45,45),  0 -1px 0.5px rgb(45,45,45);
}

.profile-nameplate.nameplate-203 {
    border-top: solid 1px #D4E6F3;
    border-left: solid 1px #D4E6F3;
    border-right: solid 1px #082673;
    border-bottom: solid 1px #082673;
    background-color: #3F73DB;
    background-image: -webkit-radial-gradient(transparent 30%, #082673 100%);
    background-image:         radial-gradient(transparent 30%, #082673 100%);
    background-repeat: no-repeat;
    text-shadow: 1px 1px 0.5px rgb(45,45,45), -1px -1px 0.5px rgb(45,45,45),
    -1px 1px 0.5px rgb(45,45,45),  1px -1px 0.5px rgb(45,45,45),
    1px 0 0.5px rgb(45,45,45), -1px  0 0.5px rgb(45,45,45),
    0 1px 0.5px rgb(45,45,45),  0 -1px 0.5px rgb(45,45,45);
}

.profile-nameplate.nameplate-301 {
    border-top: solid 1px #D9ADAD;
    border-left: solid 1px #D9ADAD;
    border-right: solid 1px #8C031C;
    border-bottom: solid 1px #8C031C;
    background-color: #c90000;
    background-image: -webkit-radial-gradient(transparent 60%, rgba(255, 234, 234, 0.3) 100%);
    background-image:         radial-gradient(transparent 60%, rgba(255, 234, 234, 0.3) 100%);
    text-shadow: 1px 1px 0.5px rgb(45,45,45), -1px -1px 0.5px rgb(45,45,45),
    -1px 1px 0.5px rgb(45,45,45),  1px -1px 0.5px rgb(45,45,45),
    1px 0 0.5px rgb(45,45,45), -1px  0 0.5px rgb(45,45,45),
    0 1px 0.5px rgb(45,45,45),  0 -1px 0.5px rgb(45,45,45);
}
.profile-nameplate.nameplate-301 .nameplate-child1 {
    background-image: url("../image/66314715_p9_master1200.jpg");
    mix-blend-mode: multiply;
}
.profile-nameplate.nameplate-301 .nameplate-child2 {
    background-image: url("../image/66314715_p9_master1200.jpg");
    mix-blend-mode: multiply;
}

.profile-nameplate.nameplate-302 {
    border-top: solid 1px #D4E6F3;
    border-left: solid 1px #D4E6F3;
    border-right: solid 1px #082673;
    border-bottom: solid 1px #082673;
    background-color: #D90D1E;
    background-image: -webkit-radial-gradient(transparent 60%, rgba(255, 234, 234, 0.3) 100%);
    background-image:         radial-gradient(transparent 60%, rgba(255, 234, 234, 0.3) 100%);
    text-shadow: 1px 1px 0.5px rgb(45,45,45), -1px -1px 0.5px rgb(45,45,45),
    -1px 1px 0.5px rgb(45,45,45),  1px -1px 0.5px rgb(45,45,45),
    1px 0 0.5px rgb(45,45,45), -1px  0 0.5px rgb(45,45,45),
    0 1px 0.5px rgb(45,45,45),  0 -1px 0.5px rgb(45,45,45);
}
.profile-nameplate.nameplate-302 .nameplate-child1 {
    background-image: url("../image/66314715_p9_master1200.jpg");
    mix-blend-mode: multiply;
}
.profile-nameplate.nameplate-302 .nameplate-child2 {
    background-image: url("../image/66314715_p9_master1200.jpg");
    mix-blend-mode: multiply;
}
.profile-nameplate.nameplate-302 .nameplate-child3 {
    background-color: #0d25d9;
    mix-blend-mode: hue;
}

.profile-nameplate.nameplate-303 {
    border-top: solid 1px #E9F2A7;
    border-left: solid 1px #E9F2A7;
    border-right: solid 1px #1B4001;
    border-bottom: solid 1px #1B4001;
    background-color: #D90D1E;
    background-image: -webkit-radial-gradient(transparent 60%, rgba(255, 234, 234, 0.3) 100%);
    background-image:         radial-gradient(transparent 60%, rgba(255, 234, 234, 0.3) 100%);
    text-shadow: 1px 1px 0.5px rgb(45,45,45), -1px -1px 0.5px rgb(45,45,45),
    -1px 1px 0.5px rgb(45,45,45),  1px -1px 0.5px rgb(45,45,45),
    1px 0 0.5px rgb(45,45,45), -1px  0 0.5px rgb(45,45,45),
    0 1px 0.5px rgb(45,45,45),  0 -1px 0.5px rgb(45,45,45);
}
.profile-nameplate.nameplate-303 .nameplate-child1 {
    background-image: url("../image/66314715_p9_master1200.jpg");
    mix-blend-mode: multiply;
}
.profile-nameplate.nameplate-303 .nameplate-child2 {
    background-image: url("../image/66314715_p9_master1200.jpg");
    mix-blend-mode: multiply;
}
.profile-nameplate.nameplate-303 .nameplate-child3 {
    background-color: #21a955;
    mix-blend-mode: hue;
}

.profile-nameplate.nameplate-401 {
    border-top: solid 1px #D4E6F3;
    border-left: solid 1px #D4E6F3;
    border-right: solid 1px #082673;
    border-bottom: solid 1px #082673;
    background-color: #D90D1E;
    background-image: -webkit-radial-gradient(transparent 60%, rgba(255, 234, 234, 0.3) 100%);
    background-image:         radial-gradient(transparent 60%, rgba(255, 234, 234, 0.3) 100%);
    text-shadow: 1px 1px 0.5px rgb(45,45,45), -1px -1px 0.5px rgb(45,45,45),
    -1px 1px 0.5px rgb(45,45,45),  1px -1px 0.5px rgb(45,45,45),
    1px 0 0.5px rgb(45,45,45), -1px  0 0.5px rgb(45,45,45),
    0 1px 0.5px rgb(45,45,45),  0 -1px 0.5px rgb(45,45,45);
}
.profile-nameplate.nameplate-401 .nameplate-child1 {
    background-image: url("../image/66314715_p9_master1200.jpg");
    mix-blend-mode: multiply;
}
.profile-nameplate.nameplate-401 .nameplate-child2 {
    background-image: url("../image/66314715_p9_master1200.jpg");
    mix-blend-mode: multiply;
}
.profile-nameplate.nameplate-401 .nameplate-child3 {
    background-color: #6a21a9;
    mix-blend-mode: hue;
}

.profile-nameplate.nameplate-402 {
    border-top: solid 1px #E9F2A7;
    border-left: solid 1px #E9F2A7;
    border-right: solid 1px #1B4001;
    border-bottom: solid 1px #1B4001;
    background-color: #D90D1E;
    background-image: -webkit-radial-gradient(transparent 60%, rgba(255, 234, 234, 0.3) 100%);
    background-image:         radial-gradient(transparent 60%, rgba(255, 234, 234, 0.3) 100%);
    text-shadow: 1px 1px 0.5px rgb(45,45,45), -1px -1px 0.5px rgb(45,45,45),
    -1px 1px 0.5px rgb(45,45,45),  1px -1px 0.5px rgb(45,45,45),
    1px 0 0.5px rgb(45,45,45), -1px  0 0.5px rgb(45,45,45),
    0 1px 0.5px rgb(45,45,45),  0 -1px 0.5px rgb(45,45,45);
}
.profile-nameplate.nameplate-402 .nameplate-child1 {
    background-image: url("../image/66314715_p9_master1200.jpg");
    mix-blend-mode: multiply;
}
.profile-nameplate.nameplate-402 .nameplate-child2 {
    background-image: url("../image/66314715_p9_master1200.jpg");
    mix-blend-mode: multiply;
}
.profile-nameplate.nameplate-402 .nameplate-child3 {
    background-color: #ffe019;
    mix-blend-mode: hue;
}

.profile-nameplate.nameplate-403 {
    border-top: solid 1px #E9F2A7;
    border-left: solid 1px #E9F2A7;
    border-right: solid 1px #1B4001;
    border-bottom: solid 1px #1B4001;
    background-color: #D90D1E;
    background-image: -webkit-radial-gradient(transparent 60%, rgba(255, 234, 234, 0.3) 100%);
    background-image:         radial-gradient(transparent 60%, rgba(255, 234, 234, 0.3) 100%);
    text-shadow: 1px 1px 0.5px rgb(45,45,45), -1px -1px 0.5px rgb(45,45,45),
    -1px 1px 0.5px rgb(45,45,45),  1px -1px 0.5px rgb(45,45,45),
    1px 0 0.5px rgb(45,45,45), -1px  0 0.5px rgb(45,45,45),
    0 1px 0.5px rgb(45,45,45),  0 -1px 0.5px rgb(45,45,45);
}
.profile-nameplate.nameplate-403 .nameplate-child1 {
    background-image: url("../image/66314715_p9_master1200.jpg");
    mix-blend-mode: multiply;
}
.profile-nameplate.nameplate-403 .nameplate-child2 {
    background-image: url("../image/66314715_p9_master1200.jpg");
    mix-blend-mode: multiply;
}
.profile-nameplate.nameplate-403 .nameplate-child3 {
    background-color: #8cff00;
    mix-blend-mode: hue;
}

.profile-nameplate.nameplate-1001 {
    border: 0.5px solid;
    border-color: #888888;
    background: linear-gradient(#3A9BB3 40%, #184453 40%);
    text-shadow: 1px 1px 0.5px rgb(45,45,45), -1px -1px 0.5px rgb(45,45,45),
    -1px 1px 0.5px rgb(45,45,45),  1px -1px 0.5px rgb(45,45,45),
    1px 0 0.5px rgb(45,45,45), -1px  0 0.5px rgb(45,45,45),
    0 1px 0.5px rgb(45,45,45),  0 -1px 0.5px rgb(45,45,45);
}
.profile-nameplate.nameplate-1001 .nameplate-child1 {
    border: 3px solid;
    border-color: #000000;
    background-image: url("../image/slot/slot_d.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 5%;
}

.profile-nameplate.nameplate-1002 {
    border: 0.5px solid;
    border-color: #888888;
    background: linear-gradient(#e8e8e8 40%, #232323 40%);
    text-shadow: 1px 1px 0.5px rgb(45,45,45), -1px -1px 0.5px rgb(45,45,45),
    -1px 1px 0.5px rgb(45,45,45),  1px -1px 0.5px rgb(45,45,45),
    1px 0 0.5px rgb(45,45,45), -1px  0 0.5px rgb(45,45,45),
    0 1px 0.5px rgb(45,45,45),  0 -1px 0.5px rgb(45,45,45);
}
.profile-nameplate.nameplate-1002 .nameplate-child1 {
    border: 3px solid;
    border-color: #000000;
    background-image: url("../image/slot/slot_bar.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 5%;
}

.profile-nameplate.nameplate-1003 {
    border: 0.5px solid;
    border-color: #888888;
    background: linear-gradient(#00293c 40%, #f62a00 40%);
    text-shadow: 1px 1px 0.5px rgb(45,45,45), -1px -1px 0.5px rgb(45,45,45),
    -1px 1px 0.5px rgb(45,45,45),  1px -1px 0.5px rgb(45,45,45),
    1px 0 0.5px rgb(45,45,45), -1px  0 0.5px rgb(45,45,45),
    0 1px 0.5px rgb(45,45,45),  0 -1px 0.5px rgb(45,45,45);
}
.profile-nameplate.nameplate-1003 .nameplate-child1 {
    border: 3px solid;
    border-color: #000000;
    background-image: url("../image/slot/slot_seven.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.profile-nameplate.nameplate-2001 {
    background-image: url("../image/profile/nameplate/event1-sanka.png");
    background-size: 100% 100%;
}

.profile-nameplate.nameplate-2002 {
    background-image: url("../image/profile/nameplate/event1-3rd.png");
    background-size: 100% 100%;
}

.profile-nameplate.nameplate-2003 {
    background-image: url("../image/profile/nameplate/event1-2nd.png");
    background-size: 100% 100%;
}

.profile-nameplate.nameplate-2004 {
    background-image: url("../image/profile/nameplate/event1-1st.png");
    background-size: 100% 100%;
}

.profile-nameplate.nameplate-2005 {
    background-image: url("../image/profile/nameplate/event3-sanka.png");
    background-size: 100% 100%;
}

.profile-nameplate.nameplate-2006 {
    background-image: url("../image/profile/nameplate/event3-3rd.png");
    background-size: 100% 100%;
}

.profile-nameplate.nameplate-2007 {
    background-image: url("../image/profile/nameplate/event3-2nd.png");
    background-size: 100% 100%;
}

.profile-nameplate.nameplate-2008 {
    background-image: url("../image/profile/nameplate/event3-1st.png");
    background-size: 100% 100%;
}

.profile-nameplate.nameplate-2009 {
    background-image: url("../image/profile/nameplate/event2-sanka.png");
    background-size: 100% 100%;
}

.profile-nameplate.nameplate-2010 {
    background-image: url("../image/profile/nameplate/event4-sanka.png");
    background-size: 100% 100%;
}

.profile-nameplate.nameplate-2011 {
    background-image: url("../image/profile/nameplate/event4-3rd.png");
    background-size: 100% 100%;
}

.profile-nameplate.nameplate-2012 {
    background-image: url("../image/profile/nameplate/event4-2nd.png");
    background-size: 100% 100%;
}

.profile-nameplate.nameplate-2013 {
    background-image: url("../image/profile/nameplate/event4-1st.png");
    background-size: 100% 100%;
}

.profile.profile-nameplate {
    height: 5.5rem;
}

#achivementModal .profile-nameplate {
    height: 1.5rem;
}

.profile-namebar {
    margin-top: 3vw;
}

.profile-nameplate.profile {
    width: 90vw;
}

.pc .profile-nameplate.profile {
    width: min(max(75vw, 350px), 600px);
}

.profile-nameplate.demo {
    width: 90vw;
}

.pc .profile-nameplate.demo {
    width: min(max(50vw, 350px), 400px);
}

.pc .profile-title {
    font-size: min(max(2vw, 12px), 17px);
}

.pc .profile-name {
    font-size: min(max(3vw, 16px), 25px);
}

.title.private::after {
    content:' \f023';
    font-family: "Font Awesome 6 Pro" !important;
    font-size: calc(1.0rem + .6vw) !important;
}

.profile-badge {
    margin-top: -1.2rem;
}

.pc .profile-badge {
    height: min(max(2vw, 12px), 17px);
    font-size: min(max(2vw, 12px), 17px);
    margin-top: calc(-0.8 * min(max(2vw, 12px), 17px));
}

.pc .profile-nameplate.demo .profile-badge {
    height: auto;
    font-size: min(max(2vw, 12px), 17px);
    margin-top: calc(-0.8 * min(max(2vw, 12px), 17px));
}

.profile-badge i {
    color: white;
}

.profile-intro {
    position: relative;
    display: inline-block;
    border: transparent;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    background-color: rgba(255, 255, 255, 0.75);
    color: black;
    font: black;
}

.profile-intro:before {
    content: "";
    position: absolute;
    top: -36px;
    right: 20%;
    margin-left: -15px;
    border: 18px solid transparent;
    border-bottom: 18px solid rgba(255, 255, 255, 0.75);;
    z-index: 2;
}

.record-nav li:first-child{
    margin-left: 0.4rem;
}

.record-nav .nav-link {
    background-color: var(--bs-nav-tabs-link-active-bg)
}

.record-nav .nav-link:not(.active) {
    border: 1px solid;
    border-color: var(--bs-border-color);
    opacity: 0.95;
}

.record-nav .nav-link:not(.active):hover {
    opacity: 1;
}


.pc .profile-info {
    font-size: min(max(2vw, 15px), 17px);
}

div[class~="alert"] ul {
    margin: 0;
}

#achivementModal .modal-body {
    max-height: 50vh;
    overflow-y: scroll;
}

h5.profile-youtube-heading::after {
    content: "";
    display: block;
    height: 3px;
    background: -webkit-linear-gradient(to right, rgb(179, 58, 58), transparent);
    background: linear-gradient(to right, rgb(179, 58, 58), transparent);
}