@font-face {
    font-family: 'Emoji';
    src: local('Apple Color Emoji'), local('Gecko Emoji'), local('Noto Sans Emoji'), local('Segoe UI Emoji'), local('Segoe UI Symbol'), local('Noto Sans Symbols');
}

* {
    font-family: 'BIZ UDPGothic', sans-serif;
    font-family: 'IBM Plex Sans JP', sans-serif;
}

html {
    cursor: default;
}

body {
    overflow-x: hidden;
}

.main-layout {
    width: 100%;
    height: 100%;
    min-height: 100vh;
}

.header-contents {
    width: auto;
    max-width: 400px;
    height: auto;
    text-align: center;
    margin-left: 0.8vw;
    margin-right: 0.8vw;
    z-index: 1021;
    position: relative;
}

@media (min-width: 992px) {
    .header-contents {
        width: 10rem;
    }
}

.header-contents .sticky-top {
    height: 100vh;
}

.header-contents .sticky-top > div {
    height: 100%;
}

.header-contents .container {
    padding-right: 0;
    padding-left: 0;
}

.gbfdrop-icon {
    width: 100%;
    min-width: 24px;
    height: 70%;
    min-height: 24px;
    position: relative;
}

/*
.gbfdrop-icon::after {
    display: inline-block;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    transform: scale(0.5);
    -webkit-filter: blur(2vw);
    -moz-filter: blur(2vw);
    -ms-filter: blur(2vw);
    filter: blur(2vw);
    background: linear-gradient(270deg, #0fffc1, #7e0fff);
    background-size: 200% 200%;
    animation: animateGlow 5s ease infinite;
}

@keyframes animateGlow {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
*/

.gbfdrop-icon.my-animation-poyopoyo {
    animation: my-animation-poyopoyo 0.5s ease-out 1;
}

@keyframes my-animation-poyopoyo {
    0%, 50%, 100% {
      transform: scale(1.0);
    }
    25%, 75% {
      transform: scale(1.3);
    }
}

.gbfdrop-icon.my-animation-poyooon {
    animation: my-animation-poyooon 0.9s ease-out 1;
}

@keyframes my-animation-poyooon {
    0%   { transform: scale(1.0, 1.0) translate(0%, 0%); }
    10%  { transform: scale(1.1, 0.9) translate(0%, 5%); }
    40%  { transform: scale(1.2, 0.8) translate(0%, 15%); }
    50%  { transform: scale(1.0, 1.0) translate(0%, 0%); }
    60%  { transform: scale(0.9, 1.2) translate(0%, -100%); }
    75%  { transform: scale(0.9, 1.2) translate(0%, -20%); }
    85%  { transform: scale(1.2, 0.8) translate(0%, 15%); }
    100% { transform: scale(1.0, 1.0) translate(0%, 0%); }
}

.gbfdrop-icon.my-animation-spin-stretch {
    animation: my-animation-spin-stretch 1s ease-out 1;
}

@keyframes my-animation-spin-stretch {
    50%  { transform:rotate(360deg) scale(.4, .33); }
    100% { transform:rotate(720deg) scale(1, 1); }
}

.gbfdrop-icon-event {
    animation:spin 4s linear infinite;
    border-radius: 50%;
    box-shadow:
        inset 0 0 5px #fff,
        inset 2px 0 6px violet,
        inset -2px 0 6px #0ff,
        inset 2px 0 30px violet,
        inset -20px 0 30px #0ff,
          0 0 50px #fff,
          -10px 0 60px violet,
          10px 0 60px #0ff;
}

@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.item {
    margin-bottom: -2.2rem;
}

.item img {
    width: 20px;
    height: auto;
}

.ring-down.my-animation-ring-down {
    animation:my-animation-ring-down 0.5s alternate 3;
    animation-timing-function:cubic-bezier(1,0,1,1);
}

@keyframes my-animation-ring-down {
    100% { transform:translateY(1000%) rotateZ(720deg); }
}

.ring-down-x {
    opacity: 0;
}

.ring-down-x.my-animation-ring-down-x {
    animation:my-animation-ring-down-x 1.5s linear 1;
}

@keyframes my-animation-ring-down-x {
     0% { opacity: 0; }
     5% { opacity: 0; }
    15% { opacity: 1; }
    75% { opacity: 1; }
    95% { opacity: 0; }
    100% { opacity: 0; transform:scale(1.1) translateX(500%); }
}

.ring-down-x.my-animation-hihi-down-x {
    animation:my-animation-hihi-down-x 1.5s linear 1;
}

@keyframes my-animation-hihi-down-x {
     0% { opacity: 0; }
     5% { opacity: 0; }
    15% { opacity: 1; }
    33% { transform:translateX(102%); }
    75% { opacity: 1; }
    95% { opacity: 0; }
    100% { opacity: 0; transform:scale(1.3) translateX(105%); }
}

.ring-down-x.my-animation-hihi-hakei::before {
    content: '';
    /*絶対配置で波形の位置を決める*/
    position: absolute;
    left: calc(50% - 30px);
    top: calc(50% - 15px);
    /*波形の形状*/
    /*
    border: 5px solid;
    border-image: conic-gradient(#f5f551,#5eff5e,#84a1ff,#ff45ff,#ff5a5a,#ffbc41,#f5f551) 1;
    */
    
    background: linear-gradient(to right, #f5f551,#5eff5e,#84a1ff,#ff45ff,#ff5a5a,#ffbc41,#f5f551) 0% center/200%;
    
    width: 60px;
    height: 60px;
    border-radius: 50%;
    /*はじめは不透明*/
  opacity:1;
    /*アニメーションの設定*/
    animation:1.5s circleanime2 forwards;
}

@keyframes circleanime2{
    0%{ transform: scale(0); opacity: 1; }
    50%{ transform: scale(0); opacity: 1; }
    100%{ transform:scale(2); opacity: 0; }    
}

@keyframes gaming {
    100% { background-position-x: 500%; }
}

.menu > div {
    margin-top: 2.94rem;
}

.menu-button {
    transition: 0.2s;
}

.menu-button:not(.mini):hover {
    background-color: rgb(52,58,64);
}

.menu-button h4 {
    font-size: 1.25rem;
    margin: 0;
}

#globalOffcanvasAd {
    width: 160px;
    height: 600px;
}

.profile-icon.icon-1 {
    border-color: #495057;
    background-color: rgb(43,48,53);
}

.profile-icon.icon-101 {
    border-color: #574A49;
    background-color: rgb(53,44,43);
}

.profile-icon.icon-102 {
    border-color: #747675;
    background-color: #5B5D5C;
}

.profile-icon.icon-103 {
    border-color: #a07115;
    background-color: #CA9F4B;
}

.profile-icon.icon-104 {
    border-color: #574A49;
    background-color: rgb(53,44,43);
}

.profile-icon.icon-105 {
    border-color: #747675;
    background-color: #5B5D5C;
}

.profile-icon.icon-106 {
    border-color: #a07115;
    background-color: #CA9F4B;
}

.profile-icon.icon-201 {
    border-color: #D9ADAD;
    background-color: #D90D1E;
}

.profile-icon.icon-202 {
    border-color: #E9F2A7;
    background-color: #3B7302;
}

.profile-icon.icon-203 {
    border-color: #D4E6F3;
    background-color: #3F73DB;
}

.profile-icon.icon-301 {
    border-color: #D9ADAD;
    background-color: #c90000;
}

.profile-icon.icon-302 {
    border-color: #D4E6F3;
    background-color: #0d25d9;
}

.profile-icon.icon-303 {
    border-color: #E9F2A7;
    background-color: #21a955;
}

.profile-icon.icon-401 {
    border-color: #D4E6F3;
    background-color: #6a21a9;
}

.profile-icon.icon-402 {
    border-color: #E9F2A7;
    background-color: #ffe019;
    text-shadow: 1px 1px 0.5px rgb(125,125,125), -1px -1px 0.5px rgb(125,125,125),
    -1px 1px 0.5px rgb(125,125,125),  1px -1px 0.5px rgb(125,125,125),
    1px 0 0.5px rgb(125,125,125), -1px  0 0.5px rgb(125,125,125),
    0 1px 0.5px rgb(125,125,125),  0 -1px 0.5px rgb(125,125,125);
}

.profile-icon.icon-403 {
    border-color: #E9F2A7;
    background-color: #8cff00;
    text-shadow: 1px 1px 0.5px rgb(125,125,125), -1px -1px 0.5px rgb(125,125,125),
    -1px 1px 0.5px rgb(125,125,125),  1px -1px 0.5px rgb(125,125,125),
    1px 0 0.5px rgb(125,125,125), -1px  0 0.5px rgb(125,125,125),
    0 1px 0.5px rgb(125,125,125),  0 -1px 0.5px rgb(125,125,125);
}

.profile-icon.icon-1001 {
    border-color: #888888;
    background: linear-gradient(#3A9BB3 50%, #184453 50%);
    text-shadow: 1px 1px 0.5px rgb(0,0,0), -1px -1px 0.5px rgb(0,0,0),
    -1px 1px 0.5px rgb(0,0,0),  1px -1px 0.5px rgb(0,0,0),
    1px 0 0.5px rgb(0,0,0), -1px  0 0.5px rgb(0,0,0),
    0 1px 0.5px rgb(0,0,0),  0 -1px 0.5px rgb(0,0,0);
}

.profile-icon.icon-1002 {
    border-color: #888888;
    background: linear-gradient(#e8e8e8 50%, #232323 50%);
    text-shadow: 1px 1px 0.5px rgb(0,0,0), -1px -1px 0.5px rgb(0,0,0),
    -1px 1px 0.5px rgb(0,0,0),  1px -1px 0.5px rgb(0,0,0),
    1px 0 0.5px rgb(0,0,0), -1px  0 0.5px rgb(0,0,0),
    0 1px 0.5px rgb(0,0,0),  0 -1px 0.5px rgb(0,0,0);
}

.profile-icon.icon-1003 {
    border-color: #888888;
    background: linear-gradient(#00293c 50%, #f62a00 50%);
    text-shadow: 1px 1px 0.5px rgb(0,0,0), -1px -1px 0.5px rgb(0,0,0),
    -1px 1px 0.5px rgb(0,0,0),  1px -1px 0.5px rgb(0,0,0),
    1px 0 0.5px rgb(0,0,0), -1px  0 0.5px rgb(0,0,0),
    0 1px 0.5px rgb(0,0,0),  0 -1px 0.5px rgb(0,0,0);
}

.profile-icon.icon-2001,
.profile-icon.icon-2002,
.profile-icon.icon-2003,
.profile-icon.icon-2004 {
    border-color: #C95DCE;
    background-color: #89388C;
}

.profile-icon.icon-2005,
.profile-icon.icon-2006,
.profile-icon.icon-2007,
.profile-icon.icon-2008 {
    border-color: #C95DCE;
    background-color: #89388C;
}

.profile-icon.icon-2009 {
    border-color: #5d5dce;
    background-color: #2d2d80;
}

.profile-icon.icon-2010,
.profile-icon.icon-2011,
.profile-icon.icon-2012,
.profile-icon.icon-2013 {
    border-color: #B32838;
    background-color: #2E3247;
}

.profile-icon {
    position: relative;
    border: 1px solid;
    border-radius: 50%;
}

.profile-icon a {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    color: rgb(222,226,230);
}

.center-contents.pc {
    max-width: 800px;
    border-left: 1px solid #495057;
    border-right: 1px solid #495057;
}

@media (min-width: 767px) {
    .center-contents.pc {
        padding-left: 1px;
    }
}

#sidead-left {
    width: 200px;
    left: -200px;
}

#sidead-right {
    width: 200px;
    right: -200px;
}

.body-sticky-top {
    background-color: rgba(var(--bs-body-bg-rgb), 1);
}

.body-sticky-top-top {
    height: 120px;
    z-index: 1;
}

.pc .body-sticky-top-top {
    height: 60px;
}

.body-sticky-top-top-center {
    left: 50%;
    transform: translateX(-50%);
}

.body-sticky-top:has(> div.body-sticky-top-border) {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.body-sticky-top-border {
    display: none;
}

.hello-bar {
    margin-left: 1rem;
    margin-right: 1rem;
    height: 4rem;
}

#top-menu-container {
    width: 90%;
}

#top-menu-container::before,
#top-menu-container::after {
    content: "";
    position: absolute;
    top: 0;
    width: 50px;
    height: 100%;
    z-index: 2;
    pointer-events: none;
    transition: opacity 0.3s ease;
}

#top-menu-container::before,
#top-menu-container::after {
    opacity: 0;
}

#top-menu-container.show-left::before {
    left: 0;
    background: linear-gradient(to right, #212529, transparent);
    opacity: 1;
}

#top-menu-container.show-right::after {
    right: 0;
    background: linear-gradient(to left, #212529, transparent);
    opacity: 1;
}

#top-menu-inner {
    width: max-content;
    transition: transform 0.5s ease;
}

#top-menu-inner a[role="button"] {
    color: var(--bs-secondary-text-emphasis);
    border-color: var(--bs-border-color);
}

#top-menu-inner a[role="button"]:hover,
a[role="button"][class~="top-menu-inner-popover-item"]:hover {
    color: var(--bs-secondary-text-emphasis);
    background-color: var(--bs-secondary-bg-subtle);
}

#top-menu-inner a[data-bs-toggle="popover"]::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

#slideLeft, #slideRight {
    cursor: pointer;
    transition: 0.2s;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
}

#slideLeft:hover, #slideRight:hover {
    background-color: rgb(52,58,64);
}

#nav-tab button:first-child{
    margin-left: 0.4rem;
}

.enemy-nav li:first-child{
    margin-left: 0.4rem;
}

.enemy-nav li.nav-item button.active {
    background-size: 50%;
    background-repeat: no-repeat;
    color: white;
    text-shadow: 1px 1px 0.5px rgb(0,0,0), -1px -1px 0.5px rgb(0,0,0),
    -1px 1px 0.5px rgb(0,0,0),  1px -1px 0.5px rgb(0,0,0),
    1px 0 0.5px rgb(0,0,0), -1px  0 0.5px rgb(0,0,0),
    0 1px 0.5px rgb(0,0,0),  0 -1px 0.5px rgb(0,0,0),
    1.5px 1.5px 0.5px rgb(255,255,255), -1.5px -1.5px 0.5px rgb(255,255,255),
    -1.5px 1.5px 0.5px rgb(255,255,255),  1.5px -1.5px 0.5px rgb(255,255,255),
    1.5px 0 0.5px rgb(255,255,255), -1.5px  0 0.5px rgb(255,255,255),
    0 1.5px 0.5px rgb(255,255,255),  0 -1.5px 0.5px rgb(255,255,255);
    font-weight: 500;
    padding-left: 1.5rem;
    padding-right: 1rem;
}

h5.my-statistics-heading {
    position: relative;
    margin: 0;
    font-size: 1.15rem;
    background-repeat: no-repeat;
    background-size: contain;
}

h5.my-statistics-heading::after {
    content: "";
    display: block;
    height: 3px;
    background: -webkit-linear-gradient(to right, rgb(58, 155, 179), transparent);
    background: linear-gradient(to right, rgb(58, 155, 179), transparent);
}

h5.my-statistics-heading.youtube::after {
    content: "";
    display: block;
    height: 3px;
    background: -webkit-linear-gradient(to right, rgb(179, 58, 58), transparent);
    background: linear-gradient(to right, rgb(179, 58, 58), transparent);
}

.my-statistics-content {
    font-size: 1.1rem;
}

.my-statistics-content::after {

}

h5.my-statistics-heading.hihi.tally-target-1,
h5.my-statistics-heading.hihi:has(+ #tally-target-1-hihi) {
    background-image: url("../image/baha64.webp");
}

h5.my-statistics-heading.hihi.tally-target-2,
h5.my-statistics-heading.hihi:has(+ #tally-target-2-hihi) {
    background-image: url("../image/gurande64.webp");
}

h5.my-statistics-heading.hihi.tally-target-3,
h5.my-statistics-heading.hihi:has(+ #tally-target-3-hihi) {
    background-image: url("../image/akasha64.webp");
}

.statistics-day {
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 50px 50px;
    background-blend-mode: lighten;
}

.tally-target-1 .statistics-day {
    background-image: url("../image/baha64.webp");
}

.tally-target-2 .statistics-day {
    background-image: url("../image/gurande64.webp");
}

.tally-target-3 .statistics-day {
    background-image: url("../image/akasha64.webp");
}

.my-news .my-statistics-content {
    font-size: 1.05rem;
}

.row.statistics-news {
    height: 18.5rem;
}

.numa-box {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    border-radius: 5px;
    padding-top: 0.9rem;
    padding-bottom: 0.75rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-top: 0.4rem;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    position: relative;
}

@media (max-width: 767px) {
    .numa-box {
        padding-top: 1.2rem;
        padding-bottom: 1.0rem;
    }
}

.numa-box::before {
    background-color: #212529;
    color: var(--bs-heading-color);
    content: var(--numa-box-title);
    left: 30px;
    position: absolute;
    top: -13px;
    font-size: 1.05rem;
}

h5.my-statistics-heading.artifact {
    background-image: url("../image/artifact64.png");
}

h5.my-statistics-heading.earring {
    background-image: url("../image/earring64.webp");
}

h5.my-statistics-heading.sand {
    background-image: url("../image/sand64.webp");
}

#screenshot-range {
    background-color: #212529;
}

.pc .icon-image {
    height: 1.6rem;
    width: 1.6rem;
}

.icon-image {
    height: 1.2rem;
    width: 1.2rem;
}

.pc .icon-image-small {
    height: 1.4rem;
    width: 1.4rem;
}

.icon-image-small {
    height: 1.0rem;
    width: 1.0rem;
}

.icon-image-middle {
    height: 1.8rem;
    width: 1.8rem;
}

.row.statistics-news .hihi-ranking {
    height: 13rem !important;
    overflow-y: scroll;
}

.row.statistics-news .hihi-ranking::-webkit-scrollbar { 
    display: none;
}

.row.statistics-news .ranking-row {
    height: auto;
    min-height: 5rem;
}

.row.statistics-news .ranking-row a {
    text-decoration: none;
}

.row.statistics-news .ranking-row button {
    font-size: 75%;
    padding: 2px 4px;
}

.ranking-more {
    overflow-y: auto;
    max-height: 35vh;
    padding: 0 10px;
}

.row.details .panel {
    margin: 0;
}

.row.details .panel img{
    max-width: 200px;
}

@media (min-width: 576px) {
    .row.details .panel img{
        max-width: 200px;
    }
}

.pc .row.details .panel-body {
    font-size: min(max(2vw, 12px), 20px);
}

.row.news-sns .news-list {
    height: 347px !important;
    overflow-y: scroll;
}

.row.news-sns .news-list::-webkit-scrollbar { 
    display: none;
}

.row.news-sns .news-list .one-news{
    height: auto;
    padding: 0.7rem;
}

.row.news-sns .news-list .one-news .one-news-title {
    margin-bottom: 0.5rem;
}

.pc .row.news-sns .news-list .one-news .one-news-title {
    font-size: min(max(1vw, 12px), 17px);
}

.row.news-sns .news-list .one-news .one-news-title span {
    margin-bottom: 0.3rem;
}

.row.news-sns .news-list .one-news .one-news-content {
    margin: 0.5rem;
    overflow: hidden;
}

.row.news-sns .my-news .nav-item button {
    background-color: black;
    border-bottom: black;
}

.row.news-sns .my-news {
    background-color: black;
    padding: 0;
}

.row.news-sns .twitter-wrap {
    width: 100%;
    height: 347px !important;
    position: relative;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.row.news-sns .twitter-wrap > iframe {
    z-index: 2;
}

.twitter-timeline {
    position: absolute;
    z-index: 1;
}

.credit a {
    text-decoration: none;
}

.circle {
    background: transparent;
    border-radius: 50%;
    border: 1px solid #495057;
    text-align: center;
    height: 2rem;
    width: 2rem;
    line-height: 2rem;
    cursor: pointer;
}

.circle:active {
    transform: scale(0.85);
}

.screenshot-preview {
    width: 90%;
    height: 25rem;
}

.carousel .carousel-indicators [data-bs-target] {
    background-color: #fff !important;
}

.alert ul {
    margin: 0;
}

.banner {
    height: 8rem;
}

.pc .banner {
    height: min(max(10vw, 80px), 130px);
}

.menu-mobile {
    height: 95px;
}

i[class~="fa-youtube"] {
    color: red;
}

#youtubeModal .youtubers {
    height: 15em;
}

.youtubeThumbnail {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
}

.youtubeThumbnail:hover img {
    opacity: 0.6;
}

.youtubeThumbnail .youtubeThumbnailMark {
    display: none;
}

.youtubeThumbnail:hover a + .youtubeThumbnailMark {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    color: white;
}

.youtubeThumbnail iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.youtubeChannel {
    font-size: 0.75rem;
}

.articleThumbnail {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
}

.articleTitle {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.5rem;
    height: calc(1.5rem * 3);
}

.ad-row, .ad-row2 {
    height: 280px;
}

.artifactFire {
    color: #980808 !important;
}

.artifactWater {
    color: #084298 !important;
}

.artifactEarth {
    color: #986f08 !important;
}

.artifactWind {
    color: #0f5132 !important;
}

.artifactLight {
    color: #849512 !important;
}

.artifactDark {
    color: #570898 !important;
}

.omikuji-row {
    height: 13rem;
}

@media (min-width: 768px) {
    .omikuji-row {
        height: 18rem;
    }
}

.omikuji-card {
    width: 30%;
    max-width: 170px;
    aspect-ratio: 0.75;
    max-height: calc(170px * 4 / 3);
    position: relative;
    transform-style: preserve-3d;
    cursor: pointer;
}

.omikuji-card.is-flipped {
    transition: transform 0.6s ease 0.6s;
    transform: rotateY(180deg);
}

.omikuji-card.is-flipped.is-delay {
    transition: transform 0.6s ease 1.5s;
}

.omikuji-row:has(.omikuji-card.is-flipped) .omikuji-card {
    cursor: default;
}

.omikuji-card:not(.is-flipped):hover,
.omikuji-card.is-selected {
    outline: 5px solid rgb(58, 155, 179);
}

.card-face {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    border: 1px solid #000;
    background: white;
}

.card-front {
    background: rgb(240, 251, 255);
}

.card-front .card-huchi {
    background: lightblue;
    width: 92%;
    height: 94%;
    background-image: radial-gradient(rgb(112, 183, 206) 30%, transparent 30%);
    background-size: 10px 10px;
}

.card-front .card-huchi .card-image {
    background-image: url("../image/logo_64_2.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 40%;
}

.card-back {
    color: black;
    background: rgb(240, 251, 255);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotateY(180deg);
}

.card-type-1 .card-back {
    background-image: url("../image/yubiwa_silver.png");
}

.card-type-2 .card-back {
    background-image: url("../image/yubiwa_black.png");
}

.card-type-3 .card-back {
    background-image: url("../image/satsumaimo.png");
}

.card-type-4 .card-back {
    background-image: url("../image/yubiwa_red.png");
}

.card-type-5 .card-back {
    background-image: url("../image/mammoth.png");
}

.card-type-6 .card-back {
    background-image: url("../image/hihi_drop.png");
}